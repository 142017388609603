import React from "react";
import Layout from "../components/layout"
import {getCurrentLocale, getTranslatedFields} from "../helpers/fields";

class Page extends React.Component {
    render() {
        const translatedFields = getTranslatedFields(this);

        const { title, body } = translatedFields;

        const locale = getCurrentLocale(this);

        const translations = this.props.pageContext.translations;
        const includeMissingLocaleWarning = this.props.pageContext.data.fieldValues.title[getCurrentLocale(this)] === undefined;

        return (
            <Layout page={this} title={title} lang={locale} className='bezoekersinformatie' translations={translations} includeMissingLocaleWarning={includeMissingLocaleWarning}>
                <div dangerouslySetInnerHTML={{ __html: body }}>
                </div>
            </Layout>
        );
    }
}

export default Page;